import React from 'react';
import classNames from 'classnames';
import { useId } from 'hooks';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  const id = useId();
  const linearId = `linear-${id}`;
  const clipId = `monogram-clip-${id}`;

  return (
    <svg
      aria-hidden
      className={classNames('monogram', className)}
      width="45"
      height="45"
      viewBox="0 0 13000 13000"
      {...props}
    >
      <defs>
        <linearGradient id={linearId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#64bbe8"/>
          <stop offset="90%" stopColor="#c5aed3"/>
        </linearGradient>
        <clipPath id={clipId}>
          <path d="M307 12412 l-27 -28 2 -424 3 -423 110 -24 c382 -86 685 -246 872
-463 137 -159 303 -450 410 -720 23 -58 834 -2267 1802 -4910 967 -2643 1762
-4813 1766 -4822 7 -17 57 -18 851 -18 793 0 844 1 853 18 5 9 784 2125 1731
4702 947 2577 1749 4755 1783 4840 127 322 319 672 475 862 208 255 506 435
847 511 l110 24 3 423 2 424 -27 28 -27 28 -1796 0 -1796 0 -32 -33 -32 -33 2
-419 3 -420 55 -8 c348 -51 710 -146 874 -229 236 -119 340 -269 340 -488 0
-124 -37 -272 -150 -610 -97 -288 -2152 -6147 -2164 -6169 -5 -10 -10 -6 -18
15 -5 16 -541 1474 -1190 3239 -649 1766 -1192 3250 -1207 3299 -109 359 -48
599 185 728 154 86 530 181 843 213 l67 7 0 427 0 427 -28 27 -28 27 -2720 0
-2720 0 -27 -28z" transform="scale(1,-1) translate(0,-13000)"/>
        </clipPath>
      </defs>
      <rect clipPath={`url(#${clipId})`} fill={`url(#${linearId})`} width="100%" height="100%" />
      {highlight && (
        <g clipPath={`url(#${clipId})`}>
          <rect className="monogram__highlight" width="100%" height="100%" />
        </g>
      )}
    </svg>
  );
}

export default Monogram;
